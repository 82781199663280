import Vue from 'vue'
import App from './App.vue'

import './assets/styles.scss';

import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
Vue.use(BootstrapVue);

import { BIcon, BIconInstagram, BIconTwitter, BIconDiscord, BIconArrowUp, BIconChat } from "bootstrap-vue";
Vue.component('BIcon', BIcon);
Vue.component('BIconInstagram', BIconInstagram);
Vue.component('BIconTwitter', BIconTwitter);
Vue.component('BIconDiscord', BIconDiscord);
Vue.component('BIconArrowUp', BIconArrowUp);
Vue.component('BIconChat', BIconChat);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
