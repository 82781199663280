<template>
  <div id="app">
    <!--img alt="Vue logo" src="./assets/logo.png"-->
    <!--HelloWorld msg="Welcome to Your Vue.js App"/-->
    <b-navbar toggleable="lg" type="dark" fixed="top" variant="dark">
      <b-navbar-toggle target="nav-collapse" class="px-0" text-white></b-navbar-toggle>
      <b-navbar-brand href="#app" id="logo-menu" class="pr-2">
        <img alt="Usagi Panzer" src="./assets/logo-shield.png" />
        <span>USAGI PANZER</span>
      </b-navbar-brand>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="#collections">NFT Collections</b-nav-item>
          <b-nav-item to="#utility">Utility</b-nav-item>
          <b-nav-item to="#roadmap">Roadmap</b-nav-item>
          <b-nav-item to="#team">About</b-nav-item>
          <b-nav-item to="#collaberations">Collabs</b-nav-item>
          <b-nav-item to="#friends">Friends</b-nav-item>
        </b-navbar-nav>
      </b-collapse>

      <b-navbar-nav class="ml-auto flex-row position-absolute" style="top:0.5rem; right:0;">
        <b-navbar-brand href="https://discord.gg/Qe3rts6G9E" right target="_blank" class="social-media"><b-icon-discord /></b-navbar-brand>
        <b-navbar-brand href="https://twitter.com/usagipanzer/" right target="_blank" class="social-media"><b-icon-twitter /></b-navbar-brand>
        <b-navbar-brand href="https://www.instagram.com/usagipanzer/" right target="_blank" class="social-media"><b-icon-instagram /></b-navbar-brand>
        <b-navbar-brand href="https://t.me/usagipanzer" right target="_blank" class="social-media"><b-icon-chat /></b-navbar-brand>
      </b-navbar-nav>
    </b-navbar>

    <b-button v-show="isScrolled" to="#app" id="scroll-to-top" variant="dark">
      <b-icon-arrow-up />
    </b-button>

    <b-container id="header">
      <b-row class="justify-content-center">
        <b-col class="text-center">
          <img src="./assets/usagi-panzer-universe-header-portrait.png" class="w-100 d-sm-block d-none" />
          <img src="./assets/usagi-panzer-universe-header-landscape.png" class="w-100 d-block d-sm-none" />
        </b-col>
      </b-row>
    </b-container>

    <b-container id="universe" class="pt-5">
      <b-row class="justify-content-center">
        <b-col md="10" lg="8" class="text-center">
          <h1>Usagi Panzer Universe</h1>
          <p>Raiding treasures from all corners of the universe where they can get their paws on. A fully NFT driven universe with cute animals armed to the teeth and ready to kill!!! Despite the fact that each NFT is a unique piece of art, you are joining the Usagi Panzer army! Growing and expanding the brand over time and you will take advantage of it.</p>
        </b-col>
      </b-row>
    </b-container>

    <b-container id="collections" class="pt-5">
      <b-row>
          <b-col class="text-center">
            <h2>NFT Collections</h2>
            <div class="responsive-card-container">
              <div class="responsive-card">
                <img alt="Usagi Panzer" src="./assets/usagi-panzer-nft-0001-isamu.png" class="w-100">
                <h3>Usagi Panzer</h3>
                <p>Cute bunnies armed to the teeth and ready to kill!!!</p>
                <b-button href="https://opensea.io/collection/usagipanzer" target="_blank" variant="primary" class="mx-1">@OpenSea | Ethereum</b-button>
              </div>
              <div class="responsive-card">
                <img alt="Dragon Hoard" src="./assets/dragon-hoard-logo.png" class="w-100">
                <h3>Dragon Hoard</h3>
                <p>Dragons, greedy and keeping huge hoards.</p>
                <b-button href="https://opensea.io/collection/dragon-hoard" target="_blank" variant="primary" class="mx-1">@OpenSea | Polygon</b-button>
              </div>
              <div class="responsive-card">
                <img alt="Killer Whale Alert!" src="./assets/banner-killer-whale-alert.png" class="w-100">
                <h3>Killer Whale Alert!</h3>
                <p>Usagi Panzer type whales, killer whales and 'relatives'.</p>
                <b-button href="https://opensea.io/collection/killer-whale-alert" target="_blank" variant="primary" class="mx-1">@OpenSea | Polygon</b-button>
              </div>
              <div class="responsive-card">
                <img alt="Neon Fish Tank" src="./assets/neon-fish-tank.png" class="w-100">
                <h3>Neon Fish Tank</h3>
                <p>A colorful collection of Usagi Panzer style happy fishes.</p>
                <b-button href="https://objkt.com/profile/tz1aT4h7zyQzvNZkNxn4VAMghDBEz555YW3c/created" target="_blank" variant="primary" class="mx-1">@Objkt | Tezos</b-button>
              </div>
            </div>
          </b-col>
      </b-row>
    </b-container>

    <b-container id="utility" class="pt-5">
      <b-row class="justify-content-center">
        <b-col md="10" lg="8" class="text-center">
          <h2>Utility</h2>
          <p>The main goal of the Usagi Panzer universe is building the brand. Being a one man army that is able to create everything from art to smart contracts and minting dapps, the sky is the limit. My personal favourite gig to work on are video games. Having full control on every collection, modulair and cross NFT collection games are already worked on. Making it possible using the Dragon Hoard collection in a racing game as player avatars while at the same time using a hot rod car from the Skidmarks Customs collection to play with.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <div class="responsive-card-container">
            <div class="responsive-card">
              <img alt="Usagi Panzer" src="./assets/dragon-hoard-nft-0058-yoru.png" class="w-100">
              <h3>Dragon Hoard Game</h3>
              <p>A Tamagotchi type game created to show Usagi is not messing around and can do all this stuff. Dragon hodlers only!</p>
              <b-button href="https://usagipanzer.io/dragon-hoard/play/" target="_blank" variant="primary" class="mx-1">Play the Game</b-button>
            </div>
            <div class="responsive-card">
              <img alt="Dragon Hoard" src="./assets/usagi-panzer-token.png" class="w-100">
              <h3>Usagi Panzer Token</h3>
              <p>Owning this NFT token gives perks like discounts in future collections or trades. This token could also be obtained as a giveaway by friends who support me.</p>
            </div>
            <div class="responsive-card">
              <img alt="Awesomeness!" src="./assets/up-nft-0060-pet-turret-turtle.png" class="w-100">
              <h3>Upcoming awesomeness!</h3>
              <p>As the Usagi Panzer brand is growing and evolving as time goes by, as an NFT holder benefits and opportunities will increase and you will take advantage of it.</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container id="roadmap" class="pt-5">
      <b-row class="justify-content-center">
        <b-col md="10" lg="8" class="text-center">
          <h2>Roadmap</h2>
          <p>For every collection i have the same simple combined roadmap. For every collection counts that revenue from sales will be used to promote and expand the current and future collections and games.</p>
          <ul>
            <li>Each NFT is 1/1 unique and has different traits</li>
            <li>There will be plenty of giveaways to win an NFT</li>
            <li>Each buyer has a chance to gain an Usagi Panzer token</li>
            <li>Random drops</li>
            <li>New collections will be announced so you can mint your rares</li>
          </ul>
        </b-col>
      </b-row>
    </b-container>

    <b-container id="team" class="pt-5">
      <b-row class="justify-content-center">
        <b-col md="10" lg="8" class="text-center">
          <h2>About Usagi Panzer by Switching Brains</h2>
          <p>Hi, i'm Dennis van Koningsbruggen. I founded Switching Brains Game Development in 2009. Usagi Panzer is a brand created around my art style; cartoony animals with lots of weaponry and anger in them, but still cute. Usagi star in Switching Brains games since 1994 and still going strong. The OG Usagi "Fluffy" debuted on the 1994 Commodore Amiga video game Suicide Machine. Him and his friends being frequent guests since then on browsers, Windows, Android and blockchain.
            <br /><br />
            For more on Switching Brains projects check the website: <a href="https://switchingbrains.com">www.SwitchingBrains.com</a></p>
        </b-col>
      </b-row>
    </b-container>

    <b-container id="collaberations" class="pt-5">
      <b-row>
        <b-col class="text-center">
          <h2>NFT Collaberations</h2>
          <div class="responsive-card-container">
            <div class="responsive-card">
              <img alt="Usagi Panzer" src="./assets/dopey-dragons-nft-0036.png" class="w-100">
              <h3>Dopey Dragons</h3>
              <p>NFT collaberation with SplooshLife Clothing Company</p>
              <b-button href="https://opensea.io/collection/dopeydragons" target="_blank" variant="primary" class="mx-1">@OpenSea | Polygon</b-button>
            </div>
            <div class="responsive-card">
              <img alt="Dragon Hoard" src="./assets/skidmarks-customs-nft.jpg" class="w-100">
              <h3>Skidmarks Customs</h3>
              <p>A collection of the craziest custom hot rods in the metaverse.</p>
              <b-button href="https://www.skidmarkscustoms.com/" target="_blank" variant="primary" class="mx-1">Website | Polygon MATIC</b-button>
            </div>
            <div class="responsive-card">
              <img alt="Switching Brains One-Offs" src="./assets/one-offs-nft-mike.png" class="w-100">
              <h3>Switching Brains One-Offs</h3>
              <p>NFT's created as fan art, contest entries and whatsoever.</p>
              <b-button href="https://opensea.io/collection/switching-brains-one-offs" target="_blank" variant="primary" class="mx-1">@OpenSea | Polygon</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container id="friends" class="pt-5">
      <b-row>
        <b-col class="text-center">
          <h2>Frens and projects Usagi likes</h2>
          <p>Collections of friends worth supporting and checking out!</p>
          <div class="responsive-card-container">
            <div class="responsive-card">
              <img alt="Retro Carts" src="./assets/frens-retro-carts.png" class="w-100">
              <h3>Retro Carts</h3>
              <p>Each RetroCarts NFT represents a different famous car.</p>
              <b-button href="https://opensea.io/collection/retrocarts" target="_blank" variant="primary" class="mx-1">@OpenSea | Polygon</b-button>
            </div>
            <div class="responsive-card">
              <img alt="Dragon Hoard" src="./assets/frens-cute-bunny-club.jpg" class="w-100">
              <h3>Cute Bunny Club</h3>
              <p>Cute Bunny Club is created by a 9 year old girl and her father.</p>
              <b-button href="https://opensea.io/collection/cutebunnyclub" target="_blank" variant="primary" class="mx-1">@OpenSea | Polygon</b-button>
            </div>
            <div class="responsive-card">
              <img alt="Horror Bears" src="./assets/frens-horror-bears.png" class="w-100">
              <h3>Horror Bears</h3>
              <p>1/1 Hand drawn NFT Collectibles with each being unique piece of art.</p>
              <b-button href="https://opensea.io/HorrorBears" target="_blank" variant="primary" class="mx-1">@OpenSea | Polygon</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <footer class="text-center my-5">
&copy; Dennis van Koningsbruggen | Switching Brains | Usagi Panzer
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data () {
    return {
      isScrolled: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.setIsScrolled, { passive: true })
    this.setIsScrolled()
  },
  destroyed () {
    window.removeEventListener('scroll', this.setIsScrolled, { passive: true })
  },
  methods: {
    setIsScrolled () {
      this.isScrolled = window.scrollY > 480;
    }
  },
}
</script>
